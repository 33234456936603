.my-atropos {
  width: 320px;
  height: 160px;
}
#dashFooter {
  /* background-color: unset !important; */
}
.dashboaed_loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dashboard_heading {
  color: #000000;
  letter-spacing: 1px;
  font-size: 24px;
  font-weight: 500;
  font-family: 'Rubik', sans-serif;
  margin-bottom: 20px;
  text-transform: capitalize;
}
.dashboard_small_heading_blue {
  color: #001ea4;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: 500;
  font-family: 'Rubik', sans-serif;
  margin-bottom: 20px;
  text-transform: capitalize;
}
.dashboard_small_heading_dark {
  color: #1f1f1f;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: 500;
  font-family: 'Rubik', sans-serif;
  margin-bottom: 20px;
  text-transform: capitalize;
}
.dashboard_small_card {
  position: relative;
  border-radius: 10px !important;
  box-shadow: none !important;
  border: none;
  background: rgb(3 37 108);
  border: 1px solid rgba(255, 255, 255, 0.09);
  background-color: '#021e56';
  margin-bottom: 20px;
  overflow: hidden;

  transition: 0.3s all ease-in;
}
.dashboard_small_card::before {
  content: '';
  position: absolute;
  top: -43px;
  right: -60px;
  width: 150px;
  height: 150px;
  background-color: rgb(123 254 93 / 12%);
  border-radius: 50%;
}
.dashboard_small_card::after {
  content: '';
  position: absolute;
  bottom: -83px;
  left: -60px;
  width: 150px;
  height: 150px;
  background-color: rgb(252 66 74 / 20%);

  border-radius: 50%;
}
.card_hover:hover {
  transform: scale(1.05);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}
.dashboard_small_card .card-title {
  font-size: 14px;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  color: #fff;
}
.dashboard_small_card .text-muted {
  color: rgba(255, 255, 255, 0.5) !important;
  font-size: 13px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
}
.dashboard_small_card .font-rubik {
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  font-family: 'Rubik', sans-serif;
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.703125rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  margin-top: 20px;
}
.h-2 {
  height: 0.5rem !important;
}
.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  transition: width 0.6s ease;
}

.w-80 {
  width: 80% !important;
}
.bg-danger {
  background: #f5334f;
}
.badge-danger {
  color: #fff;
  background: #f5334f !important;
}
.bg-secondary {
  background: #00b3ff !important;
}

.badge-secondary {
  color: #fff;
  background: #00b3ff !important;
}

.bg-primary {
  background: #0cc974 !important;
}

.badge-primary {
  color: #fff;
  background: #0cc974 !important;
}
.bg-yellow {
  background: #0cc912cc !important;
}

.badge-yellow {
  color: #fff;
  background: #0cc912cc !important;
}

.dashboard_graph_card h2 {
  font-size: 24px;
  color: #fff;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
  padding: 15px 0 10px 10px;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.graph_box {
  padding: 20px 10px 20px 10px;
}
.grapg_header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.09);
}

.table_header {
  background-color: rgb(102 117 148);
  color: #fff;
}
.table th,
.table td {
  color: #fff;
}

.table tr {
  border-bottom: 1px solid #8585859b;
}

.table_bg {
  background-color: #e9f8fc;
}

.table_bg .table th,
.table td {
  color: #000;
}
.table_bg label {
  color: #000;
}

.desposte_report_main {
  background-color: #ffffff;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
}

.desposte_report_main h1 {
  font-size: 24px;
  color: #001ea4;
  font-weight: 500;
}
.desposte_report_main .company_name {
  font-size: 20px;
  color: #000000;
  font-weight: 500;
}

.login_form_main_div {
  background-color: #fff;
  height: 100vh;
  width: 100%;
}

.brand-logo {
  max-width: 100px;
  /* margin: 50px 0 0 50px; */
}

.form_div {
  /* background-color: whitesmoke; */
  height: 70vh;
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  margin: auto;
  margin-right: 0;
  padding: 40px 20px;
}

.form_div h1 {
  font-size: 36px;
  font-weight: 500;
  font-family: 'Rubik', sans-serif;
  color: #013540;
  letter-spacing: 1px;
}

.form_div h6 {
  font-size: 18px;
  font-weight: 500;
  font-family: 'Rubik', sans-serif;
  color: #1f1f1f;
  letter-spacing: 1px;
  margin-bottom: 50px;
}

.admin_login_form input {
  border-radius: 8px;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  color: #1f1f1f;
  background-color: transparent;
}
.input_field {
  border-radius: 8px;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  color: #1f1f1f;
  background-color: transparent;
  padding: 0.94rem 0.75rem;
  border: 1px solid #2c2e33 !important;
  width: 100%;
  padding-left: 50px;
}
.admin_login_form .Login-button {
  width: 100%;
  padding: 16px;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  color: #fff;
  background-color: #013540;
}

.login_input_field_inner {
  position: relative;
}

.resend_otp_div {
  margin-bottom: 20px;
  display: flex;
  align-items: end;
  width: 85%;
  justify-content: end;
}
.login_input_field_inner span {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 1rem;
  font-weight: 600;
  color: #003441;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
}

.login_img_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login_img_animation {
  animation: jumpTwo 8s linear infinite;
}
@keyframes jumpTwo {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  40% {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
.d_flex_both_center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.today_overview {
  font-size: 14px;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  color: #171717;
}

@media screen and (max-width: 991px) {
  .card .card-body {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 768px) {
  .dashboard_heading {
    font-size: 20px;
  }

  .d_flex_both_center {
    align-items: flex-start;
    flex-direction: column;
  }
  .d_flex_both_center select.form-control,
  .d_flex_both_center .rmdp-container {
    min-width: 100%;
  }
  .d_flex_both_center .rmdp-input {
    width: 100%;
  }

  .form_div {
  
    width: 100%;
  }
}
