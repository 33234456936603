.multi-select-dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .selected-options {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .selected-option {
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 4px;
  }
  
  .placeholder {
    color: #999;
  }
  
  .options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: #fff;
  }
  
  .option {
    display: block;
    padding: 8px;
    cursor: pointer;
  }
  
  .option:hover {
    background-color: green;
  }
  
  .selected {
    background-color: green;
    border: 1px solid black;
  }
  
  .open .options {
    display: block;
  }
  
  .open .selected-options {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .search_input{
    border: 2px solid black;
    width: 100%;
    height: 35px;
    padding: 5px;
  }
  .search_input:focus{
    border: 2px solid green;
  }
  