.chat-container {
  width: 100%;
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-image: url('https://w0.peakpx.com/wallpaper/818/148/HD-wallpaper-whatsapp-background-cool-dark-green-new-theme-whatsapp.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.chat-content {
  max-width: 450px;
  margin-top: 66px;
  margin-left: 19px;
}
.unseen_msg {
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: green;
  /* padding: 4px 4px 4px 4px; */
  border-radius: 50%;
  color: #fff;
  font-size: 11px;
  width: 20px;
  height: 20px;
}

.unseen_msg span {
  justify-content: center;
  display: flex;
  align-items: center;
}

/* .chat-header {
  padding: 12px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #ccc;
  background-color: #007bff;
  color: white;
} */

.row-reverse {
  text-align: right;
}

.chat-messages {
  height: 500px;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.message {
  /* display: flex; */
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
}

.user-message {
  display: inline-block !important;
  /* background-color: #e2f2ff;*/
  background-color: #e2f2ff;
  margin-left: auto;
  max-width: 50% !important;
  width: auto !important;
  padding: 5px;
}
.msg_bg {
  display: inline;

  border-radius: 5px;
  word-break: break-all;
}
.admin-message {
  margin-right: auto;
  max-width: 50% !important;
  width: auto !important;
  background-color: #46ffe6;
  padding: 5px;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 8px;
  border-top: 1px solid #ccc;
}

.chat-input input {
  flex: 1;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 5px !important;
}

.chat_input_modal {
  flex: 1;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 5px !important;
}

.chat-input input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}
.chat_input_modal:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.chat-input button {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #0056b3;
}
.modal_button {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-left: 10px !important;
}
.modal_button:hover {
  background-color: #0056b3;
}
.chat-header {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  background-color: #007bff;
  color: white;
}

.chat_list {
  font-size: 17px;
  cursor: pointer;
}
.chat_list_cont {
  position: absolute;
  background-color: #ccc;
  width: 25%;
  height: 85%;
  overflow-x: scroll;
  overflow-x: hidden;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .chat_list_cont {
    width: 50%;
  }
}
@media screen and (max-width: 500px) {
  .chat_list_cont {
    width: 80%;
  }
}

.chat_list_cont .chat_single_div {
  padding: 10px;
  margin-bottom: 1px;
  border-bottom: 2px solid white;
  font-weight: bold;
}

.end_chat_btn {
  display: flex;
  justify-content: center;
  /* width: 100%; */
}
.end_chat_btn button {
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

/* SearchBox */
.SearchBox {
  /* position: sticky; */
  top: 0px;
  z-index: 99;
  border-radius: 0px !important;
}

.loaderReturn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgb(255, 255, 255);
}
.loader-overlay {
  background-image: url('https://api.probattle.in/public/icon/1684930333690-1-867297072.webp');
}

/* ===========New chat================ */
.chat_main_section {
  margin-top: 20px;
}
.chat_main_section .card .card-body {
  padding: 10px;
  /* background-color: #003441; */
}
.chat_main_section {

  display: flex;
}

.chat_side_list_div {
  width: 25%;
  min-height: 600px;
  max-height: 600px;
  overflow-y: auto;
  /* padding: 5px; */
  position: relative;
}
.chat_msg_side {
  width: 75%;
  position: relative;
}
/* width */
.custom_scroll::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.custom_scroll::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
}

/* Handle */
.custom_scroll::-webkit-scrollbar-thumb {
  background: #0056b3;
  border-radius: 10px;
}

/* Handle on hover */
.custom_scroll::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
.chat_main_section .small_box {
  width: 0%;
  display: none;
  /* background-color: yellow; */
}
.chat_main_section .big_box {
  width: 100%;
  /* background-color: green; */
}

.user_single_chat_list{
  background-color: #003441;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #b0b0b0;
}

.user_single_chat_list:last-child {
  border-bottom: none;
}
.user_single_chat_list h3 {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.user_single_chat_list h4 {
  font-size: 14px;
  font-weight: 500;
  color: #b1b1b1;
}

.msg_number {
  font-size: 18px;
  font-weight: 400;
  background-color: #0056b3;
  color: #fff !important;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat_msg_side_main {
  background-color: #fff;
  min-height: 500px;
  max-height: 600px;
  overflow-y: auto;
}
.chat_msg_main_div {
  padding: 10px 10px;
  margin: 80px 0;
}

.chat_msg {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.right_side_msg {
  max-width: 50%;
  width: fit-content;
  margin-right: auto;
  background-color: #f5f6f7;
}
.left_side_msg {
  max-width: 50%;
  width: fit-content;
  margin-left: auto;
  background-color: #3b71ca;
  color: #fff;
}
.chat_msg p {
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 22px;
}

.msg_time_date {
  font-size: 12px;
  display: block;
  margin-top: 10px;
  text-align: right;
}
.chat_box_search{
  border-bottom: 1px solid #333333;
  padding: 0 10px;
  }
  .side_chat_search{
    padding-top: 15px;
    position: sticky;
    background-color: #003441;
    top: 0px;
  }

.chat_box_search input{
box-shadow: none;
border: 1px solid #000;
padding: 5px;
width: 100%;
border-radius: 5px;
margin-top: 20px;

}

.chat_box_search{
  border-bottom: 1px solid #333333;
  padding: 0 10px;
  }


  .chat_container_top_bar{
    background-color: #003441;
    padding: 15px 10px;
    border-left: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .chat_container_top_bar h3{
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    margin-left: 10px;
  }

.chat_container_top_bar button {
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.chat_container_top_bar button .icon {
  color: #fff;
  font-size: 20px;
}

.profile_icon {
  font-size: 26px;
  color: #fff;
  margin-right: 10px;
}
.profile_icon:hover {
  text-transform: capitalize;
  color: #fff;
}

.msg_container_footer {
  background-color: #003441;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  border-left: 1px solid #fff;
}

.msg_container_footer_inner {
  display: flex;
  width: 100%;
}
.msg_type_input_box {
  width: 100%;
  position: relative;
}
.msg_type_input_box input {
  width: 100%;
  border: 1px solid #ffffff;
  padding: 5px;
  box-shadow: none;
  color: #fff;
  background-color: transparent;
  border-radius: 5px;
}

.msg_type_input_box input::placeholder {
  color: #fff;
}
.msg_type_input_box button {
  position: absolute;
  right: 1%;
  top: 50%;
  transform: translate(0, -50%);
  box-shadow: none;
  border: none;
  background-color: #0056b3;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
}

.choose_image_btnn {
  /* background-color: red; */
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.choose_image_btnn .icon {
  font-size: 22px;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .user_single_chat_list {
    padding: 5px;
  }
}

@media screen and (max-width: 767px) {
  .chat_side_list_div {
    position: absolute;
    width: 70% !important;
    display: none;
    left: 0;
    z-index: 9;
    height: 100%;
    background: #003441;
  }
  .chat_main_section .small_box {
    display: block;
  }
  .chat_msg_side {
    width: 100%;
  }
  .chat_main_section .card .card-body {
    padding: 0;
  }
  .chat_container_top_bar button .icon {
    position: relative;
    z-index: 99;
  }
  .chat_box_search {
    margin-top: 30px;
  }
  .right_side_msg,
  .left_side_msg {
    max-width: 100%;
  }
  .content-wrapper {
    padding: 10px;
  }
}
